<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        :to="createPromocode"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      :resource="resource"
      :columns="columns"
      :filters="filters"
      :per-page="100"
    />
  </div>
</template>

<script>
import {
  VxTable,
  VxCellPromocodeBonusInfo,
  VxCellTooltip,
  VxCellBadges,
  VxCellButtons,
  VxCellTextCopy,
  VxCellDate
} from '@/components/table'
import { promocodes, passDataToResource, promocodeLink, boolStatuses } from '@/services/resources'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { buildRoute, path } from '@/router'
import { permissions, useCan } from '@/plugins/acl'
import { sortOrders } from '@/services/table'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { boolStatusFormat } from '@/services/utils'

export default {
  name: 'Promocodes',
  components: {
    VxPageTitleActions,
    VxButton,
    VxTable,
    VxCellPromocodeBonusInfo,
    VxCellTooltip,
    VxCellBadges,
    VxCellTextCopy,
    VxCellButtons,
    VxCellDate
  },
  setup () {
    const resource = passDataToResource(promocodes.getAll, {
      requestParams: {
        params: { include: 'bonus,tags' }
      }
    })

    const canCreate = useCan(permissions.promocodeCreate)
    const canUpdate = useCan(permissions.promocodeUpdate)

    const createPromocode = path.createPromocode

    const actionButtons = ({ id }) => {
      const detailsButton = {
        ...buttons.edit,
        label: 'Details',
        can: canUpdate,
        to: buildRoute(path.promocodeDetails, { params: { id } })
      }

      return [detailsButton]
    }

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'code'
      },
      {
        key: 'link',
        component: VxCellTextCopy,
        tdAttr: {
          hideText: true
        },
        formatter: (value, key, item) => {
          return promocodeLink(item.code)
        }
      },
      {
        key: 'bonus',
        component: VxCellPromocodeBonusInfo
      },
      {
        key: 'notes',
        label: 'Description',
        component: VxCellTooltip
      },
      {
        key: 'message',
        component: VxCellTooltip
      },
      {
        key: 'max_usage_count',
        sortable: true
      },
      {
        key: 'tags',
        label: 'Promocode tag',
        component: VxCellBadges,
        tdAttr: {
          labelKey: 'name'
        }
      },
      {
        key: 'is_disabled',
        label: 'Enabled',
        component: VxCellBadges,
        formatter: (value) => boolStatusFormat(!value),
        tdAttr: {
          colors: {
            [boolStatuses['0']]: 'danger',
            [boolStatuses['1']]: 'success'
          }
        }
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      },
      {
        key: 'created_at',
        component: VxCellDate,
        sortable: true
      }
    ]

    const filters = [
      { key: 'id' },
      { key: 'code' },
      { key: 'notes', label: 'Description' },
      { key: 'message' }
    ]

    return {
      filters,
      columns,
      resource,
      canCreate,
      createPromocode
    }

  }
}
</script>
